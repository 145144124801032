<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CHANGE BANK INFO REPORT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md3>
          <v-select
            v-model="category_id"
            class="mx-2"
            dense
            outlined
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            @change="selected_category"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-select
            v-model="month_of"
            class="mx-2"
            dense
            outlined
            label="Month of"
            :items="month_of_items"
            item-value="month_of"
            item-text="month_of"
            @change="selected_category"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-select
            v-model="sorted_by"
            class="mx-2"
            :items="['1-15','16-30/31']"
            label="Sorted By"
            required
            outlined
            dense
            @change="selected_category"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-simple-table dense class="mt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              No.
            </th>
            <th class="text-uppercase">
              Name
            </th>
            <th class="text-uppercase">
              Date Started
            </th>
            <th class="text-uppercase">
              Date Changes
            </th>
            <th class="text-uppercase">
              Duration
            </th>
            <th class="text-uppercase">
              From Position
            </th>
            <th class="text-uppercase">
              From Branch
            </th>
            <th class="text-uppercase">
              To Position
            </th>
            <th class="text-uppercase">
              To Branch
            </th>
            <th class="text-uppercase">
              Change By
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data_items"
            :key="item.id"
          >
            <td>
              {{ (data_items
              .map(function (x) {
              return x.id
              })
              .indexOf(item.id)+1) }}
            </td>
            <td>
              {{ item.employee.last_name+','+item.employee.first_name+' '+item.employee.middle_name
              }}
            </td>
            <td>
              {{ date_format(item.date_started).format('MMMM DD, YYYY') }}
            </td>
            <td>
              {{ date_format(item.date_changes).format('MMMM DD, YYYY') }}
            </td>
            <td>
              {{ duration_counter(item.date_started,item.date_changes) }}
            </td>
            <td>
              {{ item.from_position
              }}
            </td>
            <td>
              {{ item.from_branch_code
              }}
            </td>
            <td>
              {{ item.to_position
              }}
            </td>
            <td>
              {{ item.to_branch_code
              }}
            </td>
            <td>
              {{ item.employee_creator.last_name+','+item.employee_creator.first_name+' '+item.employee_creator.middle_name }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAccountOutline, mdiAccountCashOutline, mdiInformationOutline} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      category_id: '',
      month_of: '',
      sorted_by: '',
      category_items: [],
      month_of_items: [],
      data_items: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('employee_change_position_branch_history', ['initialize_change_position_branch_report', 'list_of_change_bank_info_report']),
      initialize_data() {
        this.initialize_change_position_branch_report()
          .then(response => {
            this.category_items = response.data[0].category
            this.month_of_items = response.data[0].month_of
          })
          .catch(error => {
            console.log(error)
          })
      },
      duration_counter(from,to) {
        var a = moment(to,'YYYY-MM-DD');
        var b = moment(from, 'YYYY-MM-DD');

        var years = a.diff(b, 'year');
        b.add(years, 'years');

        var months = a.diff(b, 'months');
        b.add(months, 'months');

        var days = a.diff(b, 'days');
        return years + ' years ' + months + ' months ' + days + ' days'
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_category() {
        const data = new FormData()
        data.append('category_id', this.category_id);
        data.append('month_of', this.month_of);
        data.append('sorted_by', this.sorted_by);
        this.list_of_change_bank_info_report(data)
          .then(response => {
            this.data_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
